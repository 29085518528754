import React, { useEffect, useState } from "react"
import { Match } from "@reach/router"
import Loader from "./components/Loader/Loader"
import { useRefreshToken } from "../hooks/useRefreshToken"

export default function Home() {
  const [loading, setLoading] = useState(true)
  const { loginUser, checking } = useRefreshToken()

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(delay)
  }, [])

  if (loading || checking) {
    return <Loader />
  }

  return (
    <>
      <Match path="/">
        {!loginUser
          ? (window.location.href = "/home")
          : (window.location.href = "/venue/schedule")}
      </Match>
    </>
  )
}
